// This is the scss entry file
import "../styles/index.scss";

// This is the scss entry file
import Alpine from "alpinejs";
import Clipboard from "@ryangjchandler/alpine-clipboard";
import "flowbite";

Alpine.plugin(Clipboard);
window.Alpine = Alpine;


Alpine.start();

